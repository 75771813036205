import React from "react"
import { Link } from "gatsby"

const Content = ({ name, path, number }) => (
  <span className="inline-block px-2">
    <Link to={path} className="italic underline hover:no-underline" id="post-bareadcrumb">
      {name}
    </Link>
  </span>
)

const Breadcrumb = ({ catLabel, catSlug }) => {
  return (
    <p className="inline-block px-2">
      <Content name='Home' path='/' number='1' />
      { '>' }
      { catSlug && <Content name={catLabel} path={`/${catSlug}/`} number='2' /> }
      { catSlug && '>' }
    </p>
  )
}

export default Breadcrumb
