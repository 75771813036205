import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { A } from './mdx'

const Auther = ({ className }) => {
  return (
    <div className={`${className || "mt-36"} px-8 py-10 border rounded`}>
      <div className="w-full md:w-32 md:float-left md:mr-8 text-center">
        <StaticImage
          className="block w-24 md:-mt-3 mb-4 md:mb-0 mx-auto overflow-hidden rounded-full shadow-xl"
          src="../../images/auther.png"
          alt="著者"
          placeholder="dominantColor"
        />
      </div>
      <div className="text-xs text-center md:text-left">
        <h2 className="inline font-semibold text-sm">タカハシ　ユウヤ</h2>
        <p className="mt-2">投資やプログラミング、動画コンテンツの撮影・制作・編集などが得意。元・日本料理の板前。更新のお知らせは、<A href="https://twitter.com/u_ya41216132" target="_blank" rel="noreferrer">Twitter</A>で。</p>
      </div>
    </div>
  )
}
export default Auther
