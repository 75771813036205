import React from "react"
import { render } from "react-dom"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const EmbedYoutube = ({ id, className }) => (
  <div className={className} >
    <LiteYouTubeEmbed id={id} />
  </div>
)

export default EmbedYoutube
