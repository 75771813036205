import React from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from '../components/ui/layout'
import Seo from '../components/ui/seo'
import SvgSns  from '../components/ui/svg-sns'
import Share from '../components/ui/share'

import Tweet from '../components/util/embed-tweet'
import YouTube from '../components/util/embed-youtube'

import {
  H2, H3, H4, H5, H6, P, A, Em, Strong, Break,
  Blockquote, Cite, List, OrderedList, ListItem,
  Table, Td, Th, Tr, Image,
  Contaner, Note, Small, LinkList, Button,
} from '../components/post/mdx'
import RichLink from '../components/post/rich-link'
import Tags from '../components/post/tags'
import Breadcrumb from '../components/post/breadcrumb'
import Auther from '../components/post/auther'
import Toc from '../components/post/toc'
import "../components/post/post.css"
import 'katex/dist/katex.min.css'
// import "prismjs/plugins/line-numbers/prism-line-numbers.css"



const LinkComponent = props => <Link className="my-4 text-blue-500 no-underline hover:underline" {...props} />
const TweetComponent = ({id, className, width}) => <Tweet className={`my-6 ${className}`} id={id} width={width} />
const YouTubeComponent = ({id}) => <YouTube className="my-6 rounded overflow-hidden border border-gray-100" id={id} />
const VideoTag = ({src}) => <video className="my-6 w-auto h-auto rounded overflow-hidden" muted src={src} autoPlay loop />
const ButtonTag = props => <div className="my-10 text-center md:text-left"><Button {...props} ></Button></div>
const IframeTag = props => <div className="my-6 display16-9"><iframe className="absolute top-0 left-0 w-full h-full" title="iframe-title" {...props} >{}</iframe></div>

const components = {
  Contaner, Note, Small, LinkList, Cite,
  Tweet: TweetComponent,
  YouTube: YouTubeComponent, Youtube: YouTubeComponent,
  Link: LinkComponent, RichLink,
  Video: VideoTag, Button: ButtonTag,
  h1: H2, h2: H3, h3: H4, h4: H5, h5: H6, h6: H6,
  p: P, a: A, em: Em, strong: Strong, hr: Break,
  ul: List, ol: OrderedList, li:ListItem, blockquote: Blockquote,
  table: Table, td: Td, th: Th, tr: Tr,
  iframe: IframeTag, img: Image,
}

const PostTemplate = ({ data, location }) => {
  const title = data.mdx.frontmatter.title
  const topUrl = data.site.siteMetadata.siteUrl
  const url = `${topUrl}${location.pathname}`
  const twitterid = data.site.siteMetadata.twitterid
  const items = data.mdx.tableOfContents.items
  const slug = data.mdx.slug
  const eyecatch = data.mdx.frontmatter.eyecatch ? {
    src : data.mdx.frontmatter.eyecatch.childImageSharp.original.src,
    width : data.mdx.frontmatter.eyecatch.childImageSharp.original.width,
    height : data.mdx.frontmatter.eyecatch.childImageSharp.original.height,
  } : {}
  let catSlug
  let category
  data.site.siteMetadata.categories.forEach(catMeta => {
    if ( ~slug.indexOf( `${ catMeta.slug }/` ) ) {
      catSlug = catMeta.slug
      category = catMeta.label
      return
    }
  })
  return (
    <Layout>
      <Seo
        pageTitle={title}
        pageDescription={data.mdx.frontmatter.description}
        pageUrl={location.pathname}
        pageImageUrl={eyecatch && eyecatch.src}
        pageImageWidth={eyecatch && eyecatch.width}
        pageImageHeight={eyecatch && eyecatch.height}
        pageDatePublished={data.mdx2.frontmatter.datePublished}
        pageDateModified={data.mdx2.frontmatter.dateModified}
        isArticleLdJSON={true}
        breadcrumbList={[
          {position:1, url:`${topUrl}/${catSlug}/`, name:category},
          {position:2, url:url, name:title},
        ]}
      />
      <SvgSns />
      <header className="max-w-screen-sm px-8 md:px-0 mx-auto">
        <h1 className="pt-28 text-3xl font-bold text-center leading-snug" id="top">{title}</h1>
        <div className="mt-5 text-xs text-gray-600 text-center">
          <Breadcrumb catLabel={category} catSlug={catSlug} />
          <Tags tags={data.mdx.frontmatter.tags} tagsMeta={data.site.siteMetadata.tags} />
        </div>
        <div className="mt-2 text-xs text-gray-600 text-center italic">
          <span className="inline-block px-2">Posted on {data.mdx.frontmatter.datePublished}</span>
          <span className="inline-block px-2">Updated on {data.mdx.frontmatter.dateModified}</span>
        </div>
        <div className="pt-24 px-0 md:px-8 mx-auto max-w-screen-sm">
          {
            data.mdx.frontmatter.eyecatch &&
            <GatsbyImage
              className="w-full rounded-md overflow-hidden shadow-xl border border-gray-100"
              image={ getImage( data.mdx.frontmatter.eyecatch ) }
              alt={title}
            />
          }
        </div>
      </header>
      <main className="mt-6 grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5 gap-8 md:mx-auto">
        <div className={`w-full max-w-xl mx-auto px-4 col-span1 md:row-start-1 ${items
          ? "md:col-span-3"
          : "md:col-span-4 xl:col-span-3"
        } xl:col-start-2 overflow-auto`}>
          <MDXProvider components={components}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
          {
            data.mdx.frontmatter.tags.length > 0 &&
            <div className="mt-36 -mb-24 text-sm text-gray-600 text-center">
              <span className="inline-block mr-6">同じタグの記事</span>
              <Tags tags={data.mdx.frontmatter.tags} tagsMeta={data.site.siteMetadata.tags} />
            </div>
          }
          <Auther />
        </div>
        { items ? (
          <div className="w-full mt-36 md:mx-auto pl-4 row-start-1 md:col-span-1">
            <div className="sticky md:max-h-screen top-0 pt-5 overflow-y-auto">
              <span className="block px-1 pb-2 text-3xl md:text-base font-semibold">目次</span>
              <Toc items={items} />
            </div>
          </div>
        ) : null }
        <div className="mt-36 col-span-1 md:col-span-4 xl:col-span-1 xl:col-start-1 xl:row-start-1">
          <div className="sticky top-0 pt-5">
            <Share message="記事をシェア" title={title} url={url} twitterid={twitterid} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      slug
      body
      rawBody
      tableOfContents(maxDepth: 2)
      frontmatter {
        title
        datePublished(formatString: "MMMM Do, YYYY")
        dateModified(formatString: "MMMM Do, YYYY")
        draft
        tags
        description
        eyecatch {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              width: 580
            )
            original {
              height
              width
              src
            }
          }
        }
      }
    }
    mdx2 : mdx(id: { eq: $id }) {
      frontmatter {
        datePublished(formatString: "yyyy-MM-DD")
        dateModified(formatString: "yyyy-MM-DD")
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        twitterId
        tags {
          label
          slug
        }
        categories {
          label
          slug
        }
      }
    }
  }
`
