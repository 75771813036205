import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const WrapCard = ({ isLink, linkTo, className, children }) => (<>{
  isLink ?
    <Link className={className} to={linkTo}>{children}</Link> :
    <a href={linkTo} className={className} target="_brank">{children}</a>
}</>)

const RichLinkCard = ({ isLink, linkTo, className, title, description, domain, imageSharp }) => (
  <WrapCard isLink={isLink} className={`flex justify-center items-center overflow-hidden my-6 mx-0 p-0 border rounded ${className}`} linkTo={linkTo}>
    <div className="flex-auto p-4">
      <p className="mb-2 font-semibold max-2-lines">{title}</p>
      <p className="mb-1 text-xs max-2-lines">{description}</p>
      <p className="text-xs max-1-lines">{domain}</p>
    </div>
    {
      imageSharp &&
      <GatsbyImage className="flex-none border-l" image={ getImage( imageSharp ) } alt={title} />
    }
  </WrapCard>
)

const RichLink = ({ className, slug, href, title, description, domain, }) => {
  const { posts, yamlLinks } = useStaticQuery(graphql`
    query {
      posts: allMdx(filter: {frontmatter: {draft: {eq: false}}}) {
        edges {
          node {
            slug
            frontmatter {
              title
              description
              eyecatch {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    aspectRatio: 1
                    height: 144
                  )
                }
              }
            }
          }
        }
      }
      yamlLinks: allFile(filter: {fields: {ogpImage: {eq: true}}}) {
        edges {
          node {
            fields {
              title
              description
              domain
              url
            }
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                width: 144
                height: 144
              )
            }
          }
        }
      }
    }
  `)

  const isLink = slug ? true : false
  const linkTo = isLink ? `/${slug}/` : href
  const targetEdge = isLink ?
    posts.edges.find(edge => edge.node.slug === slug ) :
    yamlLinks.edges.find(edge => edge.node.fields.url === href )

  if ( targetEdge ) {
    const title_ = isLink ?
      targetEdge.node.frontmatter.title :
      targetEdge.node.fields.title
    const description_ = isLink ?
      targetEdge.node.frontmatter.description :
      targetEdge.node.fields.description
    const domain_ = isLink ? slug : targetEdge.node.fields.domain
    const imageSharp = isLink ?
      targetEdge.node.frontmatter.eyecatch.childImageSharp :
      targetEdge.node.childImageSharp

    return <RichLinkCard
      isLink={isLink}
      linkTo={linkTo}
      className={ className || '' }
      title={ title || title_ }
      description={ description || description_ }
      domain={ domain || domain_ }
      imageSharp={imageSharp}
    />

  } else {
    return <a href={linkTo} className={className} target="_brank">{linkTo}</a>
  }

}

export default RichLink
