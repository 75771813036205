import React from "react"
import { Link } from "gatsby"



export const H2 = props => <h2 className="mt-48 mb-6 font-semibold leading-snug text-3xl" id={props.id}>{props.children}</h2>
export const H3 = props => <h3 className="toc-target mt-24 mb-6 font-semibold leading-snug text-2xl" id={props.id}>{props.children}</h3>
export const H4 = props => <h4 className="toc-target mt-12 mb-6 font-semibold leading-snug text-xl" id={props.id}>{props.children}</h4>
export const H5 = props => <h5 className="toc-target mt-8 mb-6 font-semibold leading-snug text-lg" id={props.id}>{props.children}</h5>
export const H6 = props => <h6 className="toc-target mt-8 mb-6 font-semibold leading-snug text-lg" id={props.id}>{props.children}</h6>
export const P = props => <p className="my-4" {...props} />
export const A = props => <a className="text-blue-500 no-underline hover:underline" target="_brank" href={props.href}>{props.children}</a>
export const Break = props => <hr className="my-12" {...props} />
export const Blockquote = props => <blockquote className="my-4 py-1 px-4 bg-gray-400 bg-opacity-10 rounded border-l-4 border-gray-500 border-opacity-50 text-sm leading-relaxed overflow-auto" {...props} />
export const Cite= props => <cite className="block -mt-2"><a className="text-blue-500 no-underline hover:underline" target="_blank" {...props} >{}</a></cite>
export const List = props => <ul className="my-4 ml-6 list-disc" {...props} />
export const OrderedList = props => <ol className="my-4 ml-6 list-decimal-center" {...props} />
export const ListItem = props => <li className="my-2 leading-snug" {...props} />
export const Table = props => <div className="my-6 overflow-x-auto"><table className="text-left h-10 leading-snug" {...props} /></div>
export const Td = props => <td className="py-2 px-2 text-xs whitespace-nowrap" {...props} />
export const Th = props => <th className="px-2 text-xs whitespace-nowrap" {...props} />
export const Tr = props => <tr className="border-b border-gray-500 border-opacity-50" {...props} />
export const Em = props => <em className="marker not-italic" {...props} />
export const Strong = props => <strong className="font-semibold" {...props} />
export const Image = props => <img className="my-4 rounded overflow-hidden border border-gray-100" alt="" {...props} />
export const Contaner = props => <div className="my-4 py-1 px-6 bg-gray-400 bg-opacity-10 rounded overflow-auto" {...props} />
export const LinkList = props => <div className="my-4 py-1 px-6 border rounded text-sm leading-relaxed" {...props} />
export const Note = props => <p className="my-4 text-xs text-gray-600" {...props} />
export const Small = props => <p className="text-sm leading-relaxed" {...props} />
export const Button = props => <div className="my-6 text-center md:text-left"><a className="inline-block py-3 px-10 bg-gray-800 text-white rounded shadow-xl hover:bg-gray-700" {...props} target="_brank" style={{display: "inline-block"}}>{}</a></div>
export const ButtonLink = props => <div className="my-6 text-center md:text-left"><Link className="inline-block py-3 px-10 bg-gray-800 text-white rounded shadow-xl hover:bg-gray-700 " {...props} style={{display: "inline-block"}} /></div>
