import React, { useEffect, useState } from "react"
import { Link } from 'react-scroll'



const getIds = (items) => {
  return items.reduce((acc, item) => {
    // url has a # as first character, remove it to get the raw CSS-id
    if (item.url) acc.push(item.url.slice(1))
    if (item.items) acc.push(...getIds(item.items))
    return acc;
  }, []);
}

const useActiveId = (itemIds) => {
  const [activeId, setActiveId] = useState(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) setActiveId(entry.target.id)
        });
      },
      { rootMargin: `0% 0% -85% 0%` }
    );
    itemIds.forEach((id) => {
      observer.observe(document.getElementById(id));
    });
    return () => {
      itemIds.forEach((id) => {
        observer.unobserve(document.getElementById(id));
      });
    };
  }, [itemIds]);
  return activeId;
}

const renderItems = (items, activeId) => {
  return (
    <ul className="border-l border-gray-200 pb-2 pl-4 text-sm md:text-xs xl:text-sm md:text-gray-500" id="post-toc">
      {items.map(item => (
        item.url &&
        item.url.length > 1 &&
        <li key={item.url.slice(1)}>
          <Link
            className={`block w-full py-1 px-2 -ml-1 cursor-pointer hover:underline hover:text-gray-800 leading-tight ${
              activeId === item.url.slice(1) ? "rounded md:bg-gray-500 md:bg-opacity-15 md:text-gray-800" : ""
            }`}
            to={item.url.slice(1)}
            spy={false}
            smooth={true}
            offset={-50}
            duration={200}
          >{item.title}</Link>
          {item.items && renderItems(item.items, activeId)}
        </li>
      ))}
    </ul>
  );
}


const Toc = ({ items }) => {
  const idList = getIds(items)
  const activeId = useActiveId(idList)
  return (
    <>
      {renderItems(items, activeId)}
      <Link
        className="hidden md:block w-full py-1 px-2 -ml-1 cursor-pointer hover:underline hover:text-gray-800 leading-tight text-sm md:text-xs xl:text-sm md:text-gray-500 text-right"
        to="top"
        spy={false}
        smooth={true}
        offset={-50}
        duration={200}
      >Back to Top ――</Link>
      <Link
        className="block md:hidden fixed w-16 bottom-0 right-0 bg-gray-300 cursor-pointer text-center"
        to="post-toc"
        spy={false}
        smooth={true}
        offset={-60}
        duration={200}
      ><svg xmlns="http://www.w3.org/2000/svg" width="65" height="40" viewBox="0 0 24 24" fill="none" stroke="#a0aec0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 19V6M5 12l7-7 7 7"/></svg><p className="text-xs text-gray-500 -mt-1 mb-1 italic font-bold leading-none">Back to<br/>contents</p></Link>
    </>
  )
}

export default Toc
