import React from "react"
import { TwitterTweetEmbed } from "react-twitter-embed"
import { random } from "./random"

const embedTweet = ({ id, className, width }) => {
  if ( Array.isArray( id ) && id ) {
    return (
      <div className={`${className}`} >
        <ul className="flex overflow-x-auto">
          { id.map( _id => (
            <li className="flex-none mr-8" key={random()} style={{width: width ? `${width}px` : '350px'}}>
              <TwitterTweetEmbed tweetId={_id} options={{width: width ? width : 350,}} />
            </li>
          ))}
        </ul>
      </div>
    )
  } else {
    return (
      <div className={`${className}`} >
        <TwitterTweetEmbed tweetId={id} options={{width: width ? width : 350,}} />
      </div>
    )
  }
}

export default embedTweet
